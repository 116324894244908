import React from "react"

import Carousel from "react-material-ui-carousel"
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
} from "@mui/material"
import TripOriginIcon from "@mui/icons-material/TripOrigin"

import { AiFillStar } from "react-icons/ai"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./carousel-component-testimonials.scss"

const CarouselComponent = () => {
  const testimonialsCarouselImagesData = useStaticQuery(graphql`
    query MyTestimonialsCarouselQuery {
      avatar_img: file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  var testimonials = [
    { className:"testimonial_comments",
      name: "Nadir Ali",
      comments:
        "Ready to Munch makes life so much easier and more convenient. Whenever I am in a jam, they're there to help me find a place nearby and order all my favorite food. Plus, their customer service is the best. Whenever I had an issue with my orders, they were happy to help.",
      img: testimonialsCarouselImagesData.avatar_img.childImageSharp.fluid,
      rating: 4.2,
    },
    { className:"testimonial_comments",
      name: "Shumaila Ghufran",
      comments:
        "I use Ready to Munch every chance I can when food is on the minds of my friends. For deliveries, and late-night cravings, I find myself using Ready to Munch almost every day. It's a great app that has never disappointed me or my friends.",
      img: testimonialsCarouselImagesData.avatar_img.childImageSharp.fluid,
      rating: 5,
    },
    { className:"testimonial_comments",
      name: "Usman Khan",
      comments:
        "I just want to say that I am so happy that there is a service like this. With upcoming finals and projects, I just don't have the time to sit down and deal with my food. This gives me so much freedom to try new food or give myself something to eat without having to go out.",
      img: testimonialsCarouselImagesData.avatar_img.childImageSharp.fluid,
      rating: 4.9,
    },
  ]
  return (
    <Box className="carousel_wrapper_testimonials">
      <Carousel
        className="actual__carousel__testimonials"
        fullHeightHover={false}
        IndicatorIcon={<TripOriginIcon style={{ fill: "#39B54A",width: "10px" , height: "10px",}} />}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "#39B54A", // 2
            fill: "#39B54A",
            color: "#39B54A",
            width: "10px" , height: "10px",
          },
        }}
        indicatorContainerProps={{
          style: {
            margin: "50px auto 0", // 5
            display:"flex",
            justifyContent:"space-between",
            width:"60px",
          },
        }}
      >
        {testimonials.map((item, idx) => {
          return (
            <Paper
              sx={{
                width: "100%",
                border: "none",
                boxShadow: "none",
              }}
              className="testimonials__paper"
            >
              <Typography className="testimonial_comments">
                {item.comments}
              </Typography>
              <Box className="testimonial_rating_box">
                <Avatar
                  className="testimonials_avatar_container"
                  alt={item.name}
                >
                  <Img className="testimonials_avatar" fluid={item.img} />
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    flexGrow: 1,
                  }}
                >
                  <Typography className="testimonial_name">
                    {item.name}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AiFillStar className="testimonial_star" />
                    <Typography
                      className="testimonial_rating"
                      sx={{ flexGrow: 1 }}
                    >
                      {item.rating}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )
        })}
      </Carousel>
    </Box>
  )
}

export default CarouselComponent
