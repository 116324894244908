import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from "react-icons/ai"
import { GoChevronRight } from "react-icons/go"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"
import Img from "gatsby-image"
import React from "react"
import "./home-section.scss"

export const HomeSection = () => {
  const homeImagesData = useStaticQuery(graphql`
    query MyQuery {
      banner_img: file(relativePath: { eq: "banner_img.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      roll_image: file(relativePath: { eq: "rollImg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sauce_image: file(relativePath: { eq: "sauceImg_home.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container maxWidth="lg" className="container_home">
      <Img
        className="rollImage_home"
        fluid={homeImagesData.roll_image.childImageSharp.fluid}
      />
      <Container maxWidth="lg">
        <Box container className="gridContainer_home">
          <Box
            className="leftGrid_home"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div className="tag_container_home">
                <Box className="tag_home">
                  <Typography className="Typography">READY TO MUNCH</Typography>
                </Box>
                <Img
                  className="sauceImage_home"
                  fluid={homeImagesData.sauce_image.childImageSharp.fluid}
                />
              </div>
              <Typography id="title_home">
                Online Food <br /> Ordering App
              </Typography>
              <Typography id="desc_home">
                Ready to Munch offers a long and detailed list of the best
                restaurants nearby with menu items at the same restaurants'
                price, with no extra fee available to order food Online with
                home delivery and takeaway options.
              </Typography>
              <Button
                onClick={() =>
                  window.open(
                    "https://app.readytomunch.com/restaurants",
                    "_blank"
                  )
                }
                endIcon={ 
                  <GoChevronRight
                  className="endIcon" />
                }
                id="btn_home"
              >
                Order Now
              </Button>
            </Box>

            <Box className="iconIcontainer_home desktop-social-wrapper">
              <IconButton>
                <AiFillInstagram className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/readytomunch_rtm/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <FaFacebookF className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/Readytomunchh/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <AiOutlineTwitter className="socialIcon"
                  onClick={() =>
                    window.open("https://twitter.com/ReadyToMunch", "_blank")
                  }
                />
              </IconButton>
              <IconButton>
                <FaLinkedinIn className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/ready-to-munch/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <AiFillYoutube className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCceTmKdRtgUy3H3mXbAfuzA",
                      "_blank"
                    )
                  }
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            className="rightGrid_home"
          >
            <Img
              className="bannerImg_home"
              fluid={homeImagesData.banner_img.childImageSharp.fluid}
              style={{ top: "5%", borderRadius: "20px" }}
            />
          </Box>
          <Box className="iconIcontainer_home mobile-social-wrapper">
              <IconButton>
                <AiFillInstagram className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/readytomunch_rtm/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <FaFacebookF className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/Readytomunchh/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <AiOutlineTwitter className="socialIcon"
                  onClick={() =>
                    window.open("https://twitter.com/ReadyToMunch", "_blank")
                  }
                />
              </IconButton>
              <IconButton>
                <FaLinkedinIn className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/ready-to-munch/",
                      "_blank"
                    )
                  }
                />
              </IconButton>
              <IconButton>
                <AiFillYoutube className="socialIcon"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCceTmKdRtgUy3H3mXbAfuzA",
                      "_blank"
                    )
                  }
                />
              </IconButton>
            </Box>
        </Box>
      </Container>
    </Container>
  )
}
