import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./stats-section.scss";
export const StatsSection = () => {
  const statsArray = [
    { value: "80+", desc: "Number of Restaurants" },
    { value: "50K", desc: "Number of User" },
    { value: "18", desc: "Number of Cities" },
    { value: "1M", desc: "Total Downloads" },
    { value: "100K", desc: "Order" },
  ];
  return (
    <Container maxWidth="md" className="container_stats">
      <Box container className="gridContainer_stats">
        {statsArray.map((item, idx) => {
          return (
            <Box
              className="gridItem_stats"
            >
              <Box className="cardBox_stats">
                <Typography id="value_stats">{item.value}</Typography>
                <Typography id="desc_stats">{item.desc}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};
